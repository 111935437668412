import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
import { PostUpdateTitle } from '../../components/post-update-title/post-update-title';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Web accessibility is an ethical duty and economically viable. You contribute to the inclusion of people with disabilities
and increase your potential client base. Still, as a web developer, you might be thinking: `}<ItalicText mdxType="ItalicText">{`“That's great for
my company and society in general. But it means more work for me, argh!”`}</ItalicText></p>
    <p>{`I get it! Web accessibility can be overwhelming in the beginning. And yes, some stuff like
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions"
      }}>{`ARIA live regions`}</a>{` actually increase
your workload slightly. But I want to let you in on a little secret: Accessibility will make your life as a web
developer easier and save you time in the long run!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAF3teXDHCv/xAAaEAACAwEBAAAAAAAAAAAAAAABAgAREgMT/9oACAEBAAEFAmi9dSwYwIGaVk8z/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAEREv/aAAgBAwEBPwGmpf/EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAgEBPwGpj//EABsQAQACAgMAAAAAAAAAAAAAAAEAAhJhEDJx/9oACAEBAAY/Asglqp4hFzrXU7b4xJ//xAAcEAEAAwACAwAAAAAAAAAAAAABABEhMUFRYdH/2gAIAQEAAT8hTjbfHUuJU4xsGaVg7WuWcxHwhqtnsJ//2gAMAwEAAgADAAAAECsv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAh/9oACAEDAQE/EDUg3//EABYRAQEBAAAAAAAAAAAAAAAAAAEAIf/aAAgBAgEBPxBxDF//xAAcEAADAQADAQEAAAAAAAAAAAABESEAMUFRgaH/2gAIAQEAAT8QI7pyTBR4xQzgwCcfnGdmHJNz80IwLFVeYrSXYYNw94XAFZxvzf/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A web developer sitting at her desk, facing three monitors. Lines of code are visible on a monitor.",
          "title": "A web developer sitting at her desk, facing three monitors. Lines of code are visible on a monitor.",
          "src": "/static/b6f50ec986167ffd580a454bdc89babf/e5166/pexels-thisisengineering-web-dev-workplace.jpg",
          "srcSet": ["/static/b6f50ec986167ffd580a454bdc89babf/f93b5/pexels-thisisengineering-web-dev-workplace.jpg 300w", "/static/b6f50ec986167ffd580a454bdc89babf/b4294/pexels-thisisengineering-web-dev-workplace.jpg 600w", "/static/b6f50ec986167ffd580a454bdc89babf/e5166/pexels-thisisengineering-web-dev-workplace.jpg 1200w", "/static/b6f50ec986167ffd580a454bdc89babf/b17f8/pexels-thisisengineering-web-dev-workplace.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © ThisIsEngineering / pexels.com`}</em></p>
    <p>{`You don't believe me? Then keep on reading for three examples of the superior experience that accessibility brings.`}</p>
    <h2>{`1. Semantic markup is easier to read and maintain`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.w3schools.com/html/html5_semantic_elements.asp"
      }}>{`Semantic markup`}</a>{` doesn't only convey information about the meaning
of each element to assistive technologies. It also conveys this information to developers who need to read it, modify it or
refactor it.`}</p>
    <p>{`Imagine you're joining a new team and getting familiar with the codebase. Would you be happy to find this kind of code?`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<div class="fixed-header" role="banner">
    <div id="header-logo" role="img" aria-label="Logo Example Page"></div>
    <div role="navigation" aria-label="Main Navigation">
        <div role="list">
            <div role="listitem"><a href="/">Home</a></div>
            <div role="listitem"><a href="/about">About</a></div>
        </div>
    </div>
</div>
<div role="main">
    <div role="heading" aria-level="1">Best Pizza in Town</div>
    <div role="heading" aria-level="2">Pizza Salami</div>
    <div id="pizza-salami" role="img" aria-label="Pizza with salami slices"></div>
    <div>Some text</div>
    <div role="heading" aria-level="2">Pizza Capricciosa</div>
    <div id="pizza-capri" role="img" aria-label="Pizza with baked ham, mushroom and artichoke"></div>
    <div>Some text</div>
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Damn, that's a lot of `}<InlineCode mdxType="InlineCode">{`div`}</InlineCode>{` tags! Kinda hard to read. Well, wouldn't you rather see this
HTML code instead:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<header>
    <img src="/header-logo.svg" alt="Logo Example Page">
    <nav aria-label="Main Navigation">
        <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about">About</a></li>
        </ul>
    </nav>
</header>
<main>
    <h1>Best Pizza in Town</h1>
    <h2>Pizza Salami</h2>
    <img src="/pizza-salami.jpg" alt="Pizza with salami slices">
    <p>Some text</p>
    <h2>Pizza Capricciosa</h2>
    <img src="/pizza-capri.jpg" alt="Pizza with baked ham, mushroom and artichoke">
    <p>Some text</p>
</main>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Ah, that's better! Now you can talk about the HTML code with your teammates in a meaningful way. Fun fact: Both code
examples would result in the same accessible experience for screen reader users. But only the second one is beneficial for
web developers.`}</p>
    <p>{`If you think that I exaggerated with my example of spaghetti code hell, let me tell you this: I've seen variations of
this on many websites out there. Which makes me sad.`}</p>
    <h2>{`2. Native HTML controls are keyboard operable by default`}</h2>
    <p>{`HTML elements like `}<InlineCode mdxType="InlineCode">{`a`}</InlineCode>{`, `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{`, `}<InlineCode mdxType="InlineCode">{`input type="checkbox"`}</InlineCode>{`,
or `}<InlineCode mdxType="InlineCode">{`input type="radio"`}</InlineCode>{` are all accessible by default. For example, users can navigate between buttons
using the tab key and activate their selection with the space or enter key.`}</p>
    <p>{`Imagine, for whatever reason, you want to implement a button using the `}<InlineCode mdxType="InlineCode">{`div`}</InlineCode>{` element. To make this
abomination operable through a keyboard, you would have to do the following:`}</p>
    <ul>
      <li parentName="ul">{`Apply `}<InlineCode mdxType="InlineCode">{`tabindex="0"`}</InlineCode>{` to include the div in the tab order.`}</li>
      <li parentName="ul">{`Define a clearly visible focus indicator to convey when the div has focus.`}</li>
      <li parentName="ul">{`Implement a `}<InlineCode mdxType="InlineCode">{`keydown`}</InlineCode>{` event handler to activate the “button” when the space or enter key is pressed.`}</li>
    </ul>
    <p>{`Here's what you need to do using the native `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{` element:`}</p>
    <ul>
      <li parentName="ul">{`Nothing!`}</li>
    </ul>
    <p>{`Buttons are keyboard operable by default and trigger the `}<InlineCode mdxType="InlineCode">{`click`}</InlineCode>{` event when the space or
enter key is pressed. And you can easily customize their styling with CSS. This is also true for most form elements.
Check out my `}<a parentName="p" {...{
        "href": "/styling-accessible-web-forms.en/"
      }}>{`post about styling web forms`}</a>{`.`}</p>
    <h2>{`3. Stateful, semantic CSS selectors are more expressive and robust`}</h2>
    <p>{`You can leverage the semantic tag names and `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA"
      }}>{`ARIA attributes`}</a>{`
for expressive and easy to understand CSS selectors
like `}<InlineCode mdxType="InlineCode">{`header nav > ul { ... }`}</InlineCode>{` or `}<InlineCode mdxType="InlineCode">{`button`}{`[`}{`aria-expanded="true"`}{`]`}{` { ... }`}</InlineCode>{`.`}</p>
    <p>{`By interweaving your CSS rules with HTML tags and attributes, you no longer need to manage extra CSS classes dynamically
with JavaScript. You can concentrate on your business logic being reflected in the HTML code alone (e.g., by
setting `}<InlineCode mdxType="InlineCode">{`aria-expanded`}</InlineCode>{` to true or false).`}</p>
    <p>{`For more examples and an in-depth look at the concept, check out the
article `}<a parentName="p" {...{
        "href": "https://benmyers.dev/blog/semantic-selectors/"
      }}>{`“Style with Stateful, Semantic Selectors”`}</a>{` by Ben Myers.`}</p>
    <h2>{`Useful Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3schools.com/html/html5_semantic_elements.asp"
        }}>{`HTML Semantic Elements (W3 Schools)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Learn/Accessibility/HTML"
        }}>{`HTML: A good basis for accessibility (MDN)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/Attribute_selectors"
        }}>{`CSS Attribute selectors (MDN)`}</a></li>
    </ul>
    <PostUpdateTitle mdxType="PostUpdateTitle">Update on 12/16/2022</PostUpdateTitle>
    <p>{`Revised section on stateful, semantic CSS selectors.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      